import React, { Component } from 'react';
import { NavLink as Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown'

import logo from './nsa-logo-new.png';
import './style.scss';

class Header extends Component {
    constructor(props) {
        super(props);

        //const bannerVisible = window.localStorage.getItem('bannerVisible');
        const bannerVisible = true;

        this.state = {expanded: false, bannerVisible: bannerVisible === null ? true : JSON.parse(bannerVisible)}

        this.toggleNavigation = this.toggleNavigation.bind(this);
        this.navClick = this.navClick.bind(this);
        this.closeBanner = this.closeBanner.bind(this);
    }
    toggleNavigation = () => {
        
            if(this.state.expanded){
                this.setState({expanded: false})
            }else{
                this.setState({expanded: true});
            }
        
        
    }
    navClick = () => {
        if(window.innerWidth < 991){
            this.toggleNavigation();
        }
        const wrapper = document.getElementById('root');
        console.log(wrapper)
        if (wrapper) {
            wrapper.scrollTop = 0;
            window.scrollTo(0, 0);
            setTimeout(() => {
                wrapper.scrollTop = 0;
                window.scrollTo(0, 0);
            }, 50);
        }
        setTimeout(() => {
            wrapper.scrollTop = 0;
            window.scrollTo(0, 0);
        }, 50);
    }

    caretClicked = (e) => {
        e.preventDefault();

        e.target.parentElement.querySelector(".nested").classList.toggle("active");
        e.target.classList.toggle("active");

        const carets = e.target.parentElement.parentElement.querySelectorAll('.caret');
        carets.forEach((caret) => {
            if(caret !== e.target && caret.classList.contains('active')) {
                caret.classList.remove('active');
                caret.parentElement.querySelector(".nested").classList.remove("active");
            }
        });
    }
    
    closeBanner = (e) => {
        e.preventDefault();
        this.setState({bannerVisible: false});
        window.localStorage.setItem('bannerVisible', false);
    }

  render() {
    return (
        <div>
        <div className={this.state.bannerVisible ? 'visible recruitment-padder' : 'recruitment-padder'}></div>
        <div className="fixed-top">
            <div id="recruitment-banner" className={this.state.bannerVisible ? 'visible' : ''}>
                <span>LIITY SUOMEN SUURIMMALLE YKSITYISEN TURVALLISUUSALAN REKRYLISTALLE!</span> <Link to="/tyonhakijalle/liityrekrylistalle" className="btn-blue">LIITY TÄSTÄ</Link>
                <a className="close-button" onClick={this.closeBanner} href="#"><i className="fa fa-times" aria-hidden="true"></i></a>
            </div>
            <Navbar collapseOnSelect expand="lg" expanded={this.state.expanded} onToggle={()=>{}} >
                <Navbar.Brand href="/">
                    <img src={logo} alt="nordic security alliance logo" className="logo" />
                </Navbar.Brand>

                <button onClick={this.toggleNavigation}  type="button" className={"navbar-toggle " + (this.state.expanded ? '' : 'collapsed')} data-toggle="collapse" data-target="#responsive-navbar-nav" aria-controls="responsive-navbar-nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>

                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto mx-auto">
                        <Link exact to="/" onClick={this.navClick} className="nav-link" activeClassName="active">Etusivu</Link>
                        <NavDropdown title="Palvelut" renderMenuOnMount={true} onClick={(e) => {if (e.target.tagName == "A") { window.location.href = "/palvelut"; }}}>
                            <ul className="tree">
                                <li><span className="caret" onClick={this.caretClicked}>Järjestyksenvalvontapalvelut</span>
                                    <ul className="nested">
                                        <li><span className="link"><a href="/jarjestyksenvalvonta#content">Kaikki palvelut</a></span></li>
                                        <li><span className="link"><a href="/jarjestyksenvalvonta/ravintolaturvallisuus#content">Ravintolaturvallisuus</a></span></li>
                                        <li><span className="link"><a href="/jarjestyksenvalvonta/tapahtumaturvallisuus#content">Tapahtumaturvallisuus</a></span></li>
                                        <li><span className="link"><a href="/jarjestyksenvalvonta/leirintaalue#content">Leirintäalueet</a></span></li>
                                        <li><span className="link"><a href="/jarjestyksenvalvonta/matkustaja-alus#content">Matkustaja-alukset</a></span></li>
                                        <li><span className="link"><a href="/jarjestyksenvalvonta/joukkoliikennejaliikenneasemat#content">Joukkoliikenne ja liikenneasemat</a></span></li>
                                        <li><span className="link"><a href="/jarjestyksenvalvonta/kauppakeskus#content">Kauppakeskukset</a></span></li>
                                    </ul>
                                </li>
                                <li><span className="caret" onClick={this.caretClicked}>Vartiointipalvelut</span>
                                    <ul className="nested">
                                        <li><span className="link"><a href="/vartiointipalvelut">Kaikki palvelut</a></span></li>
                                        <li><span className="link"><a href="/vartiointipalvelut/paikallisvartiointi#content">Paikallisvartiointi</a></span></li>
                                        <li><span className="link"><a href="/vartiointipalvelut/tyomaajateollisuusvartiointi#content">Työmaa- ja teollisuusvartiointi</a></span></li>
                                        <li><span className="link"><a href="/vartiointipalvelut/myymalavartiointi#content">Myymälävartiointi</a></span></li>
                                        <li><span className="link"><a href="/vartiointipalvelut/lastensuojelu#content">Lastensuojelu</a></span></li>
                                        <li><span className="link"><a href="/vartiointipalvelut/aulapalvelut#content">Aulapalvelut</a></span></li>
                                        <li><span className="link"><a href="/vartiointipalvelut/henkilosuojaus#content">Henkilösuojaus</a></span></li>
                                        <li><span className="link"><a href="/vartiointipalvelut/etsiva-ja-rikostenpaljastamistoiminta#content">Etsivä- ja rikostenpaljastamistoiminta</a></span></li>
                                    </ul>
                                </li>

                                <li><span className="caret" onClick={this.caretClicked}>Asiantuntijapalvelut</span>
                                    <ul className="nested">
                                        <li><span className="link"><a href="/asiantuntijapalvelut#content">Kaikki palvelut</a></span></li>
                                        <li><span className="link"><a href="/asiantuntijapalvelut/tapahtuman-turvallisuus-ja-pelastussuunnitelma#content">Tapahtuman turvallisuus. ja pelastussuunnitelma</a></span></li>
                                        <li><span className="link"><a href="/asiantuntijapalvelut/ensiapusuunnitelma#content">Ensiapusuunnitelma</a></span></li>
                                        <li><span className="link"><a href="/asiantuntijapalvelut/liikenteenohjaussuunnitelma#content">Liikenteenohjaussuunnitelma</a></span></li>
                                        <li><span className="link"><a href="/asiantuntijapalvelut/kiinteiston-pelastussuunnitelma#content">Kiinteistön pelastussuunnitelma</a></span></li>
                                        <li><span className="link"><a href="/asiantuntijapalvelut/turvallisuusvalvoja#content">Turvallisuusvalvoja</a></span></li>
                                        <li><span className="link"><a href="/asiantuntijapalvelut/turvallisuuspaallikko#content">Turvallisuuspäällikkö</a></span></li>
                                        <li><span className="link"><a href="/asiantuntijapalvelut/hse-valvoja#content">HSE-Valvoja</a></span></li>
                                    </ul>
                                </li>

                                <li><span className="caret" onClick={this.caretClicked}>Muut palvelut</span>
                                    <ul className="nested" style={{paddingBottom: "50px"}}>
                                        <li><span className="link"><a href="/muutpalvelut#content">Kaikki palvelut</a></span></li>
                                        <li><span className="link"><a href="/muutpalvelut/vaatesailytys#vaatesaeilytys">Vaatesäilytys</a></span></li>
                                        <li><span className="link"><a href="/muutpalvelut/radiopuhelin#radiopuhelin">Radiopuhelimet</a></span></li>
                                        <li><span className="link"><a href="/muutpalvelut/ensiapu#ensiapu">Ensiapu</a></span></li>
                                        <li><span className="link"><a href="/muutpalvelut/defibrillaattori#defibrillaattori">Defibrillaattori</a></span></li>
                                        <li><span className="link"><a href="/muutpalvelut/liikenteenohjaus#liikenteenohjaus">Liikenteenohjaus</a></span></li>
                                        <li><span className="link"><a href="/muutpalvelut/alkometrit#alkometrit">Alkometrit</a></span></li>
                                        <li><span className="link"><a href="/muutpalvelut/turvatekniikka#turvatekniikka">Turvatekniikka</a></span></li>
                                    </ul>
                                </li>
                            </ul>
                        </NavDropdown>

                        <Link to="/blogi" onClick={this.navClick} className="nav-link" activeClassName="active">Blogi</Link>

                        <NavDropdown title="Työnhakijalle" renderMenuOnMount={true} onClick={(e) => { if (e.target.tagName == "A") { window.location.href = "/tyonhakijalle"; }}}>
                            <ul className="tree nested">
                                <li>
                                    <span className="link"><a href="/tyonhakijalle/avoimettyopaikat">Avoimet&nbsp;työpaikat</a></span>
                                </li>

                                <li>
                                    <span className="link"><a href="/tyonhakijalle/avoinhakemus">Avoin&nbsp;hakemus</a></span>
                                </li>

                                <li>
                                    <span className="link"><a href="/tyonhakijalle/liityrekrylistalle">Liity&nbsp;rekrylistalle</a></span>
                                </li>
                            </ul>
                        </NavDropdown>

                        <Link to="/yhteystiedot" onClick={this.navClick} className="nav-link" activeClassName="active">Yhteystiedot</Link>
                        <a className="intralink mobile" href="https://nordicsec.leonidasoy.fi/login" target="_blank" rel="noopener noreferrer">Intra</a>
                    </Nav>
                </Navbar.Collapse>

                <a className="intralink desktop" href="https://nordicsec.leonidasoy.fi/login" target="_blank" rel="noopener noreferrer">Intra</a>
            </Navbar>
        </div>
        </div>
    );
  }
}

export default Header;
