import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQ';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage23WhyChoose from '../../components/ServicePage23WhyChoose';
import Testimonial from '../../components/Testimonial';
import ServicePageForm from '../../components/ServicePageForm';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_3_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
          <h2>Asiantuntijapalvelut</h2>
        </Banner>
        <Container className="intro p-t-100 p-b-50 text-center" id="content">
          <Row className="p-b-100">
            <Col sm={{ span: 8, offset: 2}}>
              <h1>TURVALLISUUSPÄÄLLIKKÖ</h1>
              <h3>TURVALLISUUDEN KOKONAISVALTAINEN HALLINTA</h3>
              <p>
                Turvallisuuspäällikön tehtävän ytimessä on johtaa, kehittää ja edistää turvallisuutta ja turvallisuuskulttuuria sen kaikissa muodoissa sekä toimia turvallisuuden kokonaisvaltaisena asiantuntijana yrityksissä, organisaatioissa ja yhteisöissä. Turvallisuuspäällikkö toimii johdon asiantuntijana turvallisuusasioissa ja on perehtynyt turvallisuutta ohjaaviin lainsäädäntöihin, standardeihin sekä toimialaan jossa toimii.
              </p>

              <h4>Miksi turvallisuuspäällikkö on tärkeä?</h4>

              <p>Kaiken keskiössä on henkilöstön, asiakkaiden ja liiketoiminnan turvallisuus. Turvallisuuspäällikön vastuulla on varmistaa, että yritys, organisaatio tai yhteisö noudattaa turvallisuusmääräyksiä ja -standardeja, hän suunnittelee ja toteuttaa turvallisuuskoulutuksia ja -ohjelmia sekä kehittää ja ylläpitää turvallisuusjärjestelmiä ja -tekniikoita. Turvallisuuspäällikkö analysoi ja arvioi turvallisuusriskit sekä laatii suunnitelmia niiden hallitsemiseksi ja vähentämiseksi.</p>

              <p className="p-b-50">
                Turvallisuuspäällikön rooli on keskeinen yrityksen, organisaation tai yhteisön turvallisuuden varmistamisessa ja sen toiminnan tehokkuuden ja luotettavuuden takaamisessa.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray text-center">
          <Row>
            <Col>
              <h2>Myös he luottavat meihin turvallisuusasioissa:</h2>
              <NewReferences references={['noho', 'finlandia', 'oldIrish', 'jokerit', 'rajupaja', 'allas', 'vanha', 'jt']} />
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Turvallisuusjohtaminen ja hallinta</h4>
              <p>Yrityksen, organisaation, yhteisön tai tapahtuman turvallisuuden johtaminen ja kokonaisturvallisuuden hallinta turvallisuusmääräyksiin ja standardeihin perustuen.</p>

              <h4>Vaarojen ja riskien arviointi ja analysointi</h4>
              <p>Henkilöstöön, asiakkaisiin ja liiketoimintaan kohdistuvien vaarojen ja turvallisuusriskien arviointi ja analysointi.</p>

              <h4>Varautuminen</h4>
              <p>Laatii ja toimeenpanee organisaation omatoimisen varautumisen toimintamalleja erilaisten yhteiskunnan tai toiminnan häiriötilanteiden varalle. Ylläpitää viranomaisyhteistyötä.</p>

              <h4>Suunnittelu ja kouluttaminen</h4>
              <p>Turvallisuussuunnitelmien, -koulutusten ja -ohjelmien laatiminen ja toteuttaminen turvallisuusriskien hallitsemiseksi ja vähentämiseksi.</p>

              <h4>Konsultointi</h4>
              <p>Yrityksen, organisaation tai yhteisön johdon konsultointi turvallisuuteen liittyvissä asioissa ja päätöksenteossa.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/jarjestyksenvalvonta">Järjestyksenvalvontapalvelut</Link>
          <Link to="/vartiointipalvelut">Vartiointipalvelut</Link>
          <Link to="/muutpalvelut/radiopuhelin">Radiopuhelimet</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage23WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_3_1;
