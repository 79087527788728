import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Banner from '../../components/Banner'
import Navlink from '../../components/Navlink'
import Feed from '../../components/Feed'
import NewReferences from '../../components/NewReferences';
import Testimonial from '../../components/Testimonial'

import { Link } from "react-router-dom";

import service1 from './service1.png';
import service2 from './service2.png';
import service3 from './service3.png';
import service4 from './service4.png';

import './style.scss';

// import './App.scss';

class HomePage extends Component {


  render() {
    return (
      <div className="page homepage">

        
        <Banner>
            <h2>Luomme turvallisuutta</h2>
        </Banner>
        <Container className="intro p-t-100 p-b-50 text-center">
            <Row>
                <Col sm={{ span: 8, offset: 2}}>
                    <h1>NORDIC SECURITY ALLIANCE TURVALLISUUS<wbr/>PALVELUT</h1>
                    <h2>HIGH-CLASS - CONFIDENTIAL - SAFE</h2>
                    <p>
                        Tarjoamme turvallisuuspalveluita yrityksille, yksityishenkilöille, yhteisöille sekä julkiselle sektorille. Palvelemme asiakkaitamme kaikkialla Suomessa, reilun 600 henkilön voimin.
                    </p>
                    <p>
                        Tuemme liiketoimintaasi varmistamalla sinulle, yrityksellesi ja henkilöstöllesi turvallisen ja miellyttävän toimintaympäristön asiakaspalvelua unohtamatta. Henkilökunnallamme on aina alan viimeisin ja viranomaissäädösten mukainen tehtäväkohtainen koulutus - olet siis varmoissa käsissä. Toimintamme on avointa ja raportoimme aina ajantasaisesti.
                    </p>
                </Col>
            </Row>
        </Container>       

        <Container fluid className="p-t-50 p-b-50 bg-gray text-center">
            <Row sm={{ span: 8, offset: 2}}>
                <Col>
                    <h3>Oletko kiinnostunut palveluistamme?</h3>
                    <div className="p-b-50">Siirry palvelut sivullemme tai pyydä tarjous.</div>
                </Col>
            </Row>

            <Row>
                <Col lg={{span: 3, offset: 3}}>
                    <Link className="btn-blue" to="/palvelut">Siirry palveluihin</Link>
                </Col>
                <Col lg={{span: 3}}>
                    <Link className="btn-blue grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </Col>
            </Row>
        </Container>

        <Container className="p-t-50 p-b-75">
            <Row>
                <Col sm={{ span: 8, offset: 2 }}>
                    <div class="services-grid">
                        <Link to="/jarjestyksenvalvonta" className="service-1" style={{backgroundImage: `url(${service1})`}}><span className="mobile">JÄRJESTYKSENVALVONTA-<br/>PALVELUT</span><span className="desktop">JÄRJESTYKSENVALVONTAPALVELUT</span></Link>
                        <Link to="/vartiointipalvelut" className="service-2" style={{backgroundImage: `url(${service2})`}}><span className="mobile">VARTIOINTI-<br/>PALVELUT</span><span className="desktop">VARTIOINTIPALVELUT</span></Link>
                        <Link to="/asiantuntijapalvelut" class="service-3" style={{backgroundImage: `url(${service3})`}}><span className="mobile">ASIANTUNTIJA-<br/>PALVELUT</span><span className="desktop">ASIANTUNTIJAPALVELUT</span></Link>
                        <Link to="/muutpalvelut" class="service-4" style={{backgroundImage: `url(${service4})`}}><span>MUUT<br/>PALVELUMME</span></Link>
                    </div>
                </Col>
            </Row>
        </Container>

        <Container fluid className="p-t-50 p-b-50 bg-gray">
            <Container>
            <Row>
                <Col className="text-center">
                <h3>Myös he luottavat meihin turvallisuusasioissa:</h3><br/>
                <NewReferences references={['noho', 'finlandia', 'oldIrish', 'jokerit', 'familar', 'humana', 'lauste', 'jt', 'porssiTalo', 'allas', 'kulttuuri']} />
                </Col>
            </Row>
            </Container>
        </Container>

        <Container className="p-t-50 p-b-50">
            <Row>
                <Col sm={{ span: 8, offset: 2 }} className="text-center">
                    <h3 style={{ paddingBottom: '15px' }}>Oletko kiinnostunut työskentelemään yhdessä Suomen johtavista turvallisuusalan yrityksistä?</h3>
                    Katso avoimet työpaikkamme tai lähetä meille avoin työhakemus.<br/>
                    Rekrytoimme valtakunnallisesti.<br/>
                </Col>
            </Row>
        </Container>

        <Container fluid>
            <Row className="p-b-50">
                <Col md={{span: 3, offset: 3}} className="text-center">
                    <Link className="btn-blue" to="/tyonhakijalle/avoimettyopaikat#content">AVOIMET TYÖPAIKAT</Link>
                </Col>
                <Col md={{span: 3}} className="text-center">
                    <Link className="btn-blue" to="/tyonhakijalle/avoinhakemus#content">SIIRRY HAKEMUKSEEN</Link>
                </Col>
            </Row>
        </Container>

        <Container fluid className="p-t-50 p-b-50 bg-gray">
            <Testimonial />
        </Container>

        <Container className="p-t-50 p-b-50">
            <Row>
                <Col sm={{ span: 8, offset: 2 }} className="text-center">
                    <h3 className="p-b-50">Löydät meidät myös sosiaalisesta mediasta!</h3>

                    <ul className="social-links">
                        <li><a href="https://www.facebook.com/NordicSec" target="_blank" rel="noopener noreferrer" className="facebook"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com/nordicsecurityalliance/" target="_blank" rel="noopener noreferrer" className="instagram"><i className="fa fa-instagram"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/nordicsecurityalliance/" target="_blank" rel="noopener noreferrer" className="linkedin"><i className="fa fa-linkedin"></i></a></li>
                    </ul>
                </Col>
            </Row>
        </Container>

        <Container fluid className="p-t-100 p-b-100">
            <Container>
                <Row>
                    <Col span={12}>
                    <Feed />
                    </Col>
                </Row>
            </Container>
        </Container>
        {/* <Map className="map" /> */}
      </div>
    );
  }
}

export default HomePage;
