import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Banner from '../../components/Banner'
import { Link } from 'react-router-dom';
import BlogPostCard from '../../components/BlogPostCard';
import './style.scss';

class BlogIndexPage extends Component {
  /* Paginated list of blog posts, optionally filtered by tag */

  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      tags: [],
      selectedTag: null,
      loading: true,
      error: null,
    };

    this.loadPosts = this.loadPosts.bind(this);
    this.loadTags = this.loadTags.bind(this);
    this.handleTagSelect = this.handleTagSelect.bind(this);
  }

  componentDidMount() {
    this.loadTags();
    this.loadPosts();
  }

  loadTags() {
    fetch('https://nsbackend.campaigns.fi/blog_posts/public/tags')
      .then(response => response.json())
      .then(data => {
        this.setState({
          tags: data,
        });
      })
      .catch(error => {
        console.error('Error loading tags:', error);
      });
  }

  handleTagSelect(tag) {
    this.setState({ selectedTag: tag }, () => {
      this.loadPosts();
    });
  }

  loadPosts() {
    this.setState({ loading: true });
    
    let url = 'https://nsbackend.campaigns.fi/blog_posts/public';
    if (this.state.selectedTag) {
      url += `?tag=${encodeURIComponent(this.state.selectedTag)}`;
    }
    
    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.setState({
          posts: data.posts,
          loading: false,
          error: null,
        });
      })
      .catch(error => {
        this.setState({
          posts: [],
          loading: false,
          error: error,
        });
      });
  }

  render() {
    return (
      <div className="page blogpage">
        <Banner>
          <h1>Blogi</h1>
        </Banner>

        <Container className="intro p-t-50 p-b-50 text-center" id="content">
            <Row>
                <Col sm={{ span: 8, offset: 2}}>
                    <h1>Tervetuloa Nordic Security Alliancen blogin pariin!</h1>
                    <p>
                      Turvallisuus on kaikkien yhteinen asia. Blogistamme löydät ajankohtaista tietoa ja näkökulmia turvallisuusalaan ja alalla työskentelyyn liittyen. Julkaisuissa jaamme hyödyllisiä käytännön oppeja, ajankohtaisia ilmiöitä sekä toimiviksi todettuja ratkaisuja niin alasta kiinnostuneille kuin jo turvallisuusalalla toimiville.
                    </p>
 
                    <p>
                      Haluamme nostaa esiin alan keskeisiä haasteita, parhaita käytäntöjä ja kehityssuuntia, jotta voimme yhdessä rakentaa turvallisempaa tulevaisuutta ja kehittää alaa entistä paremmaksi.
                    </p>

                    <p>
                      Seuraa blogiamme ja pysy ajan tasalla turvallisuusalan muutoksista ja mahdollisuuksista!
                    </p>
                </Col>
            </Row>
        </Container>

        <Container>
          <Row className="p-t-20">
            <Col>
              <div className="tag-filter">
                <span className="tag-label">Aiheet:</span>
                <ul className="tag-list">
                  <li 
                    className={this.state.selectedTag === null ? 'active' : ''}
                    onClick={() => this.handleTagSelect(null)}
                  >
                    Kaikki
                  </li>
                  {this.state.tags.map(tag => (
                    <li 
                      key={tag} 
                      className={this.state.selectedTag === tag ? 'active' : ''}
                      onClick={() => this.handleTagSelect(tag)}
                    >
                      {tag}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>           
              <Row className="text-center p-t-30 p-b-50">
                <Col>
                  <div className="posts">
                    {this.state.loading && <p>Ladataan...</p>}
                    {!this.state.loading && this.state.posts.length === 0 && (
                      <p>Ei artikkeleita valitulla aiheella.</p>
                    )}
                    {!this.state.loading && this.state.posts.map(post => (
                      <BlogPostCard post={post} key={post.id} />
                    ))}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BlogIndexPage;
