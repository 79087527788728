import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Navlink from '../Navlink';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './style.scss';

import logoInverted from './nsa-logo-white-new.svg';
import badge from './luotettava_kumppani.png';

class Footer extends Component {
  render() {
    return (
      <Container fluid className="footer">
        <Container>
            <Row className="upper">
                <Col md={2} xs={12} >
                    <img src={logoInverted} alt="nordic security alliance logo" title="nordic security alliance logo" className="logo" />
                    <p>
                            Ruosilankuja 3 E<br />
                            00390 Helsinki
                    </p>
                    <p>
                            <a href="mailto:toimisto@nordicsec.fi">toimisto@nordicsec.fi</a>
                    </p>
                </Col>
                <Col md={8} xs={12} className="text-center">
                    <ul className="footer-nav">                    
                        <li className="current">
                        <Navlink exact to="/" onClick={this.navClick} activeClassName="active">Etusivu</Navlink>
                          </li>
                        
                          <li className="link">
                          <Navlink to="/palvelut" onClick={this.navClick} activeClassName="active">Palvelut</Navlink>
                          </li>
                        
                          <li className="link">
                          <Navlink to="/tyonhakijalle" onClick={this.navClick}  activeClassName="active">Työnhakijalle</Navlink>
                          </li>

                          <li className="link">
                          <Navlink to="/yhteystiedot" onClick={this.navClick} activeClassName="active">Yhteystiedot</Navlink>
                          </li>
                </ul>

                <a href="https://zeckit.com/selvitys/FI/2406929-9?section=quickcheck" rel="noopener" target="_blank"><img style={{width: "227px", paddingTop: "3rem"}} src="https://www.vastuugroup.fi/hubfs/VG-website/luotettava-kumppani-logo-vihrea.png" alt="Luotettava Kumppani"/></a>
                </Col>
                <Col md={2} xs={12} className="some">
                    <a href="https://nordicsec.leonidasoy.fi/login" target="_blank" rel="noopener noreferrer" className="btn-blue" >Intra</a>
                </Col>
            </Row>

            <Row className="lower">
                <Col sm={6} xs={12} className="left">
                    <p>&copy; 2025 Nordic Security Alliance Group Oy | All Rights Reserved</p>
                </Col>
                <Col sm={6} xs={12} className="right">
                    
                        <p><Navlink to="tietosuojaseloste">Tietosuojaseloste (tulossa)</Navlink></p>
                    
                </Col>
            </Row>
        </Container>
    </Container>
    );
  }
}

export default Footer;
