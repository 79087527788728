import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQ';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage23WhyChoose from '../../components/ServicePage23WhyChoose';
import Testimonial from '../../components/Testimonial';
import ServicePageForm from '../../components/ServicePageForm';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_3_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
          <h2>Asiantuntijapalvelut</h2>
        </Banner>
        <Container className="intro p-t-100 p-b-50 text-center" id="content">
          <Row className="p-b-100">
            <Col sm={{ span: 8, offset: 2}}>
              <h1>TAPAHTUMAN ENSIAPUSUUNNITELMA</h1>
              <h3>HUOLELLINEN SUUNNITTELU, TURVALLINEN TOTEUTUS</h3>
              <p>
                Tapahtuman ensiapusuunnitelmalla varmistat, että tapahtumasi ensiapuvalmius on johdettua ja suunnitelmallista, hyvin organisoitua, ensiapu valmius on riittävä ja yhteydenpito ensiapupisteiden ja -partioiden kanssa sujuvaa. Ensiapusuunnitelmassa määritetään tapahtuman ensiapuvalmius ja -pisteet, johtosuhteet, viestintä sekä toiminta erityistilanteissa.
              </p>

              <p className="p-b-50">
                Ensiapusuunnitelma on laadittava tapahtumiin, joiden samanaikainen yleisömäärä on yli 2000 henkilöä. Tapahtumaan tulee olla nimetty myös riittävän koulutuksen ja kokemuksen omaava ensiapujohtaja. Ensiapusuunnitelma liitetään osaksi tapahtuman turvallisuus- ja pelastussuunnitelmaa.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray text-center">
          <Row>
            <Col>
              <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
              <NewReferences references={['noho', 'finlandia', 'oldIrish', 'jokerit', 'rajupaja', 'allas', 'vanha', 'jt']} />
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Alkukartoitus</h4>
              <p>Kartoitamme tapahtuman luonteen, erityispiirteet, tapahtuma-alueen ja tapahtumaan kohdistuvat riskit.</p>

              <h4>Riskien arviointi ja ensiapuvalmiuden määrittäminen</h4>
              <p>Tapahtumaan kohdistuvien riskien arviointi ja ensiapuvalmiuden määrittäminen yhdessä järjestäjä organisaation ja viranomaisten kanssa.</p>

              <h4>Suunnitelman laatiminen</h4>
              <p>Tapahtuman ensiapusuunitelma laaditaan tapahtuman yleisökapasiteetin, pohjatietojen ja riskien arvioinnissa määriteltyyn riittävään ensiapuvalmiuteen perustuen.</p>

              <h4>Suunnitelman hyväksyminen</h4>
              <p>Tapahtuman ensiapusuunnitelma käydään läpi tapahtuman järjestäjän ja viranomaisten kanssa.</p>

              <h4>Perehdytys</h4>
              <p>Tapahtuman ensiapusuunnitelma perehdytetään koko tapahtumahenkilöstölle.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/jarjestyksenvalvonta/tapahtumaturvallisuus">Tapahtumaturvallisuus</Link>
          <Link to="/asiantuntijapalvelut/turvallisuuspaallikko">Turvallisuuspäällikkö</Link>
          <Link to="/asiantuntijapalvelut/tapahtuman-turvallisuus-ja-pelastussuunnitelma">Tapahtuman turvallisuus-ja pelastussuunnitelma</Link>
          <Link to="/asiantuntijapalvelut/liikenteenohjaussuunnitelma">Tapahtuman liikenteenohjaussuunnitelma</Link>
          <Link to="/muutpalvelut/ensiapu">Ensiapu</Link>
          <Link to="/muutpalvelut/liikenteenohjaus">Liikenteenohjaus</Link>
          <Link to="/muutpalvelut/vaatesailytys">Vaatesäilytys</Link>
          <Link to="/muutpalvelut/radiopuhelin">Radiopuhelimet</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage23WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_3_1;
