import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQ';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage22WhyChoose from '../../components/ServicePage22WhyChoose';
import ServicePageForm from '../../components/ServicePageForm';
import Testimonial from '../../components/Testimonial';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_1_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
          <h2>Vartiointipalvelut</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50 text-center" id="content">
          <Row>
            <Col sm={{ span: 8, offset: 2}}>
              <h1>HENKILÖSUOJAUS</h1>
              <h3>LUOTETTAVAA JA AMMATTIMAISTA VARTIOINTIPALVELUA</h3>
              <p>
                Henkilösuojauspalvelulla suojataan henkilön tai henkilöryhmän fyysistä koskemattomuutta, terveyttä ja omaisuutta erilaisilta rikoksilta sekä muilta uhilta. Henkilösuojaukseen voi liittyä teknistävalvontaa, kuljetuspalvelua tai mukana liikkuvia henkilösuojauksen ammattilaisia eli henkivartijoita. Henkilösuojauspalvelumme suunnitellaan ja toteutetaan aina yksilöllisesti asiakkaan tarpeet, toiminta ja käytettävissä olevat resurssit huomioiden. Toimimme tarvittaessa myös ulkomailla.
              </p>

              <h4>Miksi vartiointipalvelut ovat tärkeitä?</h4>
              <p>
                Henkilösuojauspalvelulla suojaudut mahdollista uhkaa vastaan ennaltaehkäisevästi. Uhka voi olla mikä tahansa henkeen, terveyteen, henkiseen hyvinvointiin tai omaisuuteen kohdistuva. Tällöin voi olla tarpeellista ryhtyä ennakoiviin toimiin henkilökohtaisen turvallisuuden lisäämiseksi. Tyypillisiä asiakkaita ovat merkittävät liike-elämän henkilöt, poliitikot, muut julkisuuden henkilöt tai henkilöt, joilla on kohonnut riski joutua rikoksen tai muunlaisen tapahtuman uhriksi.
              </p>

              <br/><br/><br/>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray">
          <Row>
            <Col>
              <Container className="bg-white p-b-25 p-t-25 text-center">
                <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                <NewReferences references={['noho', 'finlandia', 'rajupaja', 'kmarket', 'ksupermarket', 'eurooppa', 'familar', 'humana', 'lauste']} />
              </Container>
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Henkilöturvallisuus</h4>
              <p>Asiakkaan tai asiakasryhmän fyysisen koskemattomuuden, terveyden ja hyvinvoinnin suojaaminen erilaisilta rikoksilta ja uhilta.</p>

              <h4>Omaisuuden suojaaminen</h4>
              <p>Asiakkaan aineellisen ja aineettoman omaisuuden suojaaminen erilaisilta rikoksilta, uhilta ja vahingonteolta.</p>

              <h4>Kuljetuspalvelu</h4>
              <p>Kuljetusreittien suunnittelu ja pisteestä pisteeseen saatot.</p>

              <h4>Matkustusturvallisuus</h4>
              <p>Matkustusturvallisuus suunnitelma.</p>

              <h4>Tekninen valvonta</h4>
              <p>Asiakkaan tekninen valvonta.</p>

              <h4>Uhkien ja riskien arviointi</h4>
              <p>Uhka- ja riskianalyysi saatavilla olevaan tietoon pohjautuen.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/asiantuntijapalvelut/turvallisuuspaallikko">Turvallisuuspäällikkö</Link>
          <Link to="/asiantuntijapalvelut/kiinteiston-pelastussuunnitelma">Kiinteistön pelastussuunnittelma</Link>
          <Link to="/muutpalvelut/ensiapu">Ensiapu</Link>
          <Link to="/muutpalvelut/defibrillaattori">Defibrilaattorit</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage22WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_1_1;
