import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQ';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage22WhyChoose from '../../components/ServicePage22WhyChoose';
import ServicePageForm from '../../components/ServicePageForm';
import Testimonial from '../../components/Testimonial';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_1_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
          <h2>Vartiointipalvelut</h2>
        </Banner>
        <Container className="intro p-t-100 p-b-50 text-center" id="content">
          <Row className="p-b-100">
            <Col sm={{ span: 8, offset: 2}}>
              <h1>PAIKALLISVARTIOINTI</h1>
              <h3>LUOTETTAVAA JA AMMATTIMAISTA VARTIOINTIPALVELUA</h3>
              <p>
                Tuotamme vartiointipalveluita virastoihin sekä muihin toimipaikkoihin, kiinteistöihin ja erilaisille alueille suojaamaan asiakkaan päivittäistoimintaa, omaisuutta ja henkilöstöä. Huomioimme aina toiminnassamme ja tehtävien suunnittelussa asiakkaan yksilölliset turvallisuustarpeet. Toimintamme on asiakaslähtöistä, ammattimaista ja viranomaissäädösten mukaista.
              </p>

              <h4>Miksi vartiointipalvelut ovat tärkeitä?</h4>
              <p className="p-b-50">
                Paikallisvartioinnilla varmistat henkilöstösi ja omaisuutesi turvallisuuden. Samalla varmistat, että toimipaikka säilyy turvallisena ja viihtyisänä. Vartijamme tukevat toimintaasi kiinnittämällä huomiota asioihin, jotka muutoin saattaisivat jäädä vaille huomiota, ennaltaehkäisten ja estäen potentiaalisia vahinkoja, tapaturmia tai toiminnan keskeytyksiä.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray">
          <Row>
            <Col>
              <Container className="bg-white p-b-25 p-t-25 text-center">
                <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                <NewReferences references={['noho', 'finlandia', 'rajupaja', 'kmarket', 'ksupermarket', 'eurooppa', 'familar', 'humana', 'lauste']} />
              </Container>
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Henkilöturvallisuus</h4>
              <p>Henkilökunnan turvallisuuden ja työhyvinvoinnin suojaaminen erilaisilta rikoksilta, henkilöhälytyksiin vastaaminen kohteessa, ensiapu.</p>

              <h4>Omaisuuden suojaaminen</h4>
              <p>Aineellisen ja aineettoman omaisuuden suojaaminen erilaisilta rikoksilta ja vahingonteolta, erilaisiin laitteisto- ja järjestelmähälytyksiin vastaaminen kohteessa.</p>

              <h4>Aluevartiointi</h4>
              <p>Aluevartiointi jalan tai ajoneuvolla, tarkastuskierrokset, avaus- ja sulkukierrokset, työ-, palo- ja pelastusturvallisuuden valvonta, hälytysjärjestelmien aktivointi, hälytyksiin vastaaminen.</p>

              <h4>Avainhallinta</h4>
              <p>Kiinteistön tai alueen avainhallinta ja oven avaukset.</p>

              <h4>Tekninen valvonta</h4>
              <p>Kameravalvonta kiinteistön tai alueen valvomosta, kameravalvonnan suunnittelu, rikos-, palo- ja LVIS -järjestelmien valvonta.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/asiantuntijapalvelut/tapahtuman-turvallisuus-ja-pelastussuunnitelma">Tapahtuman turvallisuus- ja pelastussuunnitelma</Link>
          <Link to="/asiantuntijapalvelut/kiinteiston-pelastussuunnitelma">Kiinteistön pelastussuunnittelma</Link>
          <Link to="/muutpalvelut/radiopuhelin">Radiopuhelimet</Link>
          <Link to="/muutpalvelut/ensiapu">Ensiapu</Link>
          <Link to="/muutpalvelut/defibrillaattori">Defibrilaattorit</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage22WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_1_1;
