import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ServiceHeader from '../../components/ServiceHeader';
import ServicePageForm from '../../components/ServicePageForm';
import FAQ from '../../components/FAQ';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_2 extends Component {


  render() {
    return (
      <div className="page homepage">       
        <Banner>
            <h2>Vartiointi</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50 text-center" id="content">
            <Row className="p-b-100">
                <Col sm={{ span: 8, offset: 2}}>
                    <h1>VARTIOINTIPALVELUT</h1>
                    <h3>LUOTETTAVAA JA AMMATTIMAISTA VARTIOINTIPALVELUA</h3>
                    <p>
                      Vartiointi on kriittinen osa yhteiskunnan turvallisuusinfrastruktuuria, tarjoten suojaa niin ihmisille, omaisuudelle kuin liiketoiminnoille. Erityisesti, kun maailma muuttuu yhä ennalta-arvaamattomammaksi, vartiointipalveluiden tarve kasvaa. Myös vartiointipalvelun ammattitaito- ja muut laatuvaatimukset lisääntyvät teknologian kehittyessä ja maailman kansainvälistyessä.
                    </p>

                    <h4>Miksi vartiointipalvelut ovat tärkeitä?</h4>
                    <p className="p-b-50">
                      Vartiointi on paljon enemmän kuin pelkkää läsnäoloa kohteessa; se on tilanteiden ennakointia, ehkäisyä ja niihin reagointia erilaisin käytettävissä olevin keinoin. Vartijamme ovat koulutettuja tunnistamaan erilaiset uhat ja riskit toimintaympäristössään, sekä toimimaan välittömästi niiden ennaltaehkäisemiseksi ja estämiseksi. Vartiointipalveluiden avulla voit rauhassa keskittyä omaan päivittäistoimintaasi tietäen, että turvallisuusasiat ovat ammattilaisten käsissä.
                    </p>

                    <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </Col>
            </Row>
          </Container>

          <Container fluid className="p-b-25 p-t-25 bg-gray">
            <Row>
              <Col>
                <Container className="bg-white p-b-25 p-t-25 text-center">
                  <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                  <NewReferences references={['noho', 'finlandia', 'rajupaja', 'kmarket', 'ksupermarket', 'eurooppa', 'familar', 'humana', 'lauste']} />
                </Container>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50 p-t-50">
                <h3 className="p-b-25">Palvelumme - Räätälöityä vartiointia kaikkiin tarpeisiin</h3>
                <p>Vartiointipalvelumme on suunniteltu suojaamaan laajan kirjon erilaisia kohteita, toimialoja ja tilanteita. Aina ei kuitenkaan ole olemassa yhtä ainoaa kaikille sopivaa ratkaisua. Siksi räätälöimme palveluamme asiakkaan toiminnan ja toimialan mukaan sopivaksi.</p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Paikallisvartiointi" link="/vartiointipalvelut/paikallisvartiointi" />
                <p>Paikallisvartioinnilla takaat päivittäistoimintasi, henkilöstösi, asiakkaittesi ja omaisuutesi turvallisuuden kaikkina vuorokauden aikoina.</p>
                <p>Vartijan näkyvä läsnäolo toimitiloissasi tai tapahtumassasi, rauhoittaa ja tuo turvaa siellä asioiville ja työskenteleville henkilöille. Vartijamme toimivat aina ammattimaisesti, asiakaspalvelua unohtamatta.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/vartiointipalvelut/paikallisvartiointi">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Työmaa- ja teollisuusvartiointi" link="/vartiointipalvelut/tyomaajateollisuusvartiointi" />
                <p>Työmaa- ja teollisuusvartiointi mahdollistaa sujuvan asioinnin, kulunvalvonnan ja logistiikan kohteessasi kaikkina vuorokauden aikoina. Samalla varmistat, että työmaa- tai teollisuusalue pysyy turvallisena eikä luvattomia henkilöitä pääse alueelle.</p>
                <p>Vartijamme ovat koulutettuja huomioimaan tehtävissään myös erilaiset työturvallisuuteen liittyvät käytänteet, joita alueen haltija tai lainsäädäntö on asettanut toiminnalle. Vartijamme myös valvovat työturvallisuuden toteutumista muiden tehtäviensä ohessa.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/vartiointipalvelut/tyomaajateollisuusvartiointi">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Myymälävartiointi" link="/vartiointipalvelut/myymalavartiointi" />
                <p>Myymälävartiointi suojaa toimintaasi kaikkina vuorokauden aikoina, oli kyse sitten pienemmistä vähittäis- ja päivittäistavarakaupoista, hypermarketeista, tavarataloista tai muista erikoisliikkeistä.</p>
                <p>Vartijan läsnäolo liikkeessä tuo turvaa ja rauhoittaa asiointi ja työskentely ympäristöä erilaisilta häiriötekijöiltä. Vartijamme toimivat näkyvästi ja näkymättömästi ennaltaehkäisten ja estäen tiloissa tapahtuvia häiriötilanteita ja rikoksia, sekä avustavat ja kouluttavat liikkeen henkilöstöä tuotteiden suojauksessa.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/vartiointipalvelut/myymalavartiointi">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Lastensuojelu" link="/vartiointipalvelut/lastensuojelu" />
                <p>Lastensuojelupalveluissa keskitymme lastensuojelupalveluissa työskentelevän henkilöstön ja siellä asiakkaina olevien lasten ja nuorten turvallisuuteen. Vartijamme luovat turvaa työskentely ympäristöön ja rauhoittavat toiminnallaan myös yksikön asiakkaiden turvattomuuden tunnetta. Näin edistämme myös työntekijöiden työssä jaksamista.</p>
                <p>Lasten ja nuorten hyvinvointi on aina korkein prioriteettimme ja tuemme työntekijöitä ongelmien ratkaisemisessa.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/vartiointipalvelut/lastensuojelu">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Aulapalvelu" link="/vartiointipalvelut/aulapalvelut" />
                <p>Aulapalvelumme yhdistävät vartiointi- ja vastaanottotehtävät, tarjoten ammattimaista palvelua niin vierailijoille, työntekijöille kuin muillekin tiloissa asioiville.</p>
                <p>Hyvin toimivalla kulunvalvonnalla varmistat toimitilojen, henkilöstön sekä fyysisen ja immateriaalisen omaisuutesi turvallisuuden. Vartijamme ovat koulutettuja tarjoamaan korkealuokkaista asiakaspalvelua ja turvallisuutta samanaikaisesti.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/vartiointipalvelut/aulapalvelut">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Henkilösuojaus" link="/vartiointipalvelut/henkilosuojaus" />
                <p>Henkilösuojaus on tärkeää erityisesti sellaisille henkilöille tai henkilöryhmille, joilla on kohonnut riski joutua rikoksen tai muun sellaisen teon tai tapahtuman kohteeksi, joka voi aiheuttaa haittaa tämän terveydelle, maineelle, hyvinvoinnille tai omaisuudelle.</p>
                <p>Henkivartijamme ovat koulutettuja tarjoamaan ammattimaista ja korkeatasoista turvaa suojattavalle kaikkialla, kaikissa olosuhteissa. Henkivartijoidemme tehtäviin voi kuulua mm. suojattavan kuljettaminen, liikkumisen valvominen, mahdollisten uhkien ennaltaehkäisy turvallisten reittien suunnittelu ja tarvittaessa myös välitön toiminta uhkatilanteessa.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/vartiointipalvelut/henkilosuojaus">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Etsivä- ja rikostenpaljastamistoiminta" link="/vartiointipalvelut/etsiva-ja-rikostenpaljastamistoiminta" />
                <p>Etsivä- ja rikostenpaljastamistoiminnalla tähdätään jo tapahtuneiden rikosten tutkintaan, sekä tapahtumassa olevien rikosten estämiseen. Tehtävä voi sisältää myös muunlaista seurantaa.</p>
                <p>Etsivä- ja rikostenpaljastamistoimintaa voidaan hyödyntää useiden eri vartioimismuotojen tukitoimintana. Toiminnalla pyritään keräämään todisteet, joilla voidaan osoittaa henkilön tai henkilöiden syyllisyys tutkittavassa asiassa tai asiakokonaisuudessa. Tehtäviä hoidetaan mm. seuraamalla, dokumentoimalla, sekä tutkimalla eri järjestelmiä ja avoimia lähteitä. Rikosoikeudellisista jatkotoimenpiteistä vastaa aina poliisi.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/vartiointipalvelut/etsiva-ja-rikostenpaljastamistoiminta">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-t-75 p-b-50">
                <h3>Miksi valita Nordic Security Alliance palveluntuottajaksi?</h3>

                <h4>Vartiointipalveluidemme Laatu ja Luotettavuus</h4>
                <p>Onnistumisemme perustuu vartiointipalveluidemme korkeaan laatuun ja luotettavuuteen. Kaikki vartijamme ovat saaneet sisäasiainministeriön vaatimukset täyttävän koulutuksen ja heillä on poliisin myöntämä voimassa oleva hyväksyntä vartijan tehtävissä toimimiseen. Tarjoamme vartijoillemme myös tehtäväkohtaista lisäkoulutusta ja perehdytystä. Kehitämme toimintaamme ja käytäntöjämme jatkuvasti varmistaaksemme, että palvelumme on ja säilyy aina laadukkaana.</p>

                <h4>Yhteistyössä kohti turvallisempaa tuleivaisuutta</h4>
                <p>Olemme ylpeitä voidessamme työskennellä tiiviisti asiakkaidemme kanssa. Asiakkaan ongelmat ja huolet turvallisuusasioissa on meille sydänasia ja pyrimme aina tarjoamaan ratkaisuvaihtoehtoja näiden poistamiseksi. Otamme aina huomioon asiakkaidemme ainutlaatuiset turvallisuutarpeet räätälöidäksemme juuri heille sopivat vartiointi ja turvallisuus ratkaisut. Tarvittaessa otamme mukaan eri sidosryhmien ammattilaisia tai yhteistyökumppaneita oman toimintamme tueksi.</p>

                <h4>Ammattitaitoinen ja asiakaspalvelukeskeinen henkilökunta</h4>
                <p>
                  Laaja, alan ammattilaisista koostuva joukkomme on meille ylpeyden aihe ja merkittävin eroavaisuus muista alan toimijoista maassamme. Oikea henkilöstö oikeissa tehtävissä ja selkeät tavoitteet, luovat jatkumoa ja luottamusta asiakkaissamme.
                  <br/>
                  <span className="highlight">High-Class - Confidential - Safe</span> periaatteemme heijastuu kaikessa tekemisessämme, aina.
                </p>

                <h4>Valitsemalla vartiointipalvelumme, valitset vapauden</h4>
                <p>Valitsemalla vartiointipalvelumme, valitset vapauden keskittää omat resurssisi liiketoimintasi ja päivittäistoimintasi kannalta tärkeämpien asioiden hoitamiseen. Oli kyseessä mikä tahansa toimiala mistä tahansa kokoluokasta, olemme varmistamassa, että sinä, henkilöstösi ja asiakkaasi voivat työskennellä ja asioida turvallisessa ympäristössä. Käytämme kokemustamme, asiantuntemustamme ja omistautumistamme turvallisuuteen, jotta voit huoletta keskittyä täysin siihen, mikä sinulle on tärkeää.</p>

                <h4>Ota yhteyttä jo tänään niin katsotaan yhdessä, kuinka voimme palvella sinua parhaiten</h4>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-100 text-center">
                <Link className="btn-grey" to="/">PYYDÄ TARJOUS</Link>
              </Col>
            </Row>
        </Container>

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_2;
