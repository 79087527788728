import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQ';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage21WhyChoose from '../../components/ServicePage21WhyChoose';
import ServicePageForm from '../../components/ServicePageForm';
import Testimonial from '../../components/Testimonial';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_1_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
            <h2>Järjestyksenvalvontapalvelut</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50 text-center" id="content">
          <Row>
            <Col sm={{ span: 8, offset: 2}}>
              <h1>RAVINTOLATURVALLISUUS</h1>
              <h3>TURVALLISUUTTA JA ASIAKASPALVELUA AMMATTITAIDOLLA</h3>
              <p>
                Tuotamme järjestyksenvalvontapalveluita kaiken kokoisiin ruoka- ja viihderavintoloihin, pubeihin, yökerhoihin ja hotelleihin. Meillä on pitkä ja vahva kokemus ja osaaminen ravintoloiden turvallisuuspalveluiden tuottajana, mikä heijastuu kaikessa tekemisessämme. Toimintamme on asiakaslähtöistä, ammattimaista ja viranomaissäädösten mukaista.
              </p>

              <h4>Miksi järjestyksenvalvontapalvelut ovat tärkeitä?</h4>
              <p className="p-b-50">
                Ammattimaisen ja asiakaslähtöisen järjestyksenvalvontapalvelun ansiosta toiminta kohteessa säilyy viihtyisänä, häiriöttömänä ja turvallisena. Parhaimmillaan järjestyksenvalvojamme toimivat toimipaikkasi käyntikorttina. Järjestyksenvalvoja on usein ensimmäinen ja viimeinen henkilö, jonka asiakas ravintolassasi kohtaa.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray">
          <Row>
            <Col>
              <Container className="bg-white p-b-25 p-t-25 text-center">
                <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                <NewReferences references={['noho', 'oldIrish', 'jolene', 'kaivo', 'ilona', 'london', 'escape', 'babylon', 'ruma', 'mango', 'hollywood', 'fredrik', 'maxine', 'loyly', 'henkka', 'paappa']} />
              </Container>
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Ovimiespalvelu</h4>
              <p>Kulunvalvonta, järjestyksen ja turvallisuuden valvonta, turvallisuustarkastukset, henkilöpapereiden tarkastus, järjestyshäiriöihin puuttuminen ja muiden erilaisten tilanteiden ratkaiseminen ja asiakaspalvelu.</p>

              <h4>Salivalvoja</h4>
              <p>Järjestyksen ja turvallisuuden valvonta, palo- ja pelastusturvallisuuden valvonta, pelastustoiminnan turvaaminen, järjestyshäiriöihin puuttuminen ja muiden erilaisten tilanteiden ratkaiseminen, lasien, tuoppien ym. siivoaminen pois lattia tasoilta ja portaikoista, henkilökunnan, artistien ja muiden esiintyjien turvallisuudesta huolehtiminen, ensiapu ja asiakaspalvelu.</p>

              <h4>Raportointi</h4>
              <p>Havainnoista ja toimenpiteistä raportointi.</p>

              <h4>Eteispalvelu</h4>
              <p>Vaatesäilytys ja eteispalvelu, löytötavaroiden hallinnointi ja asiakaspalvelu.</p>

              <h4>Lipunmyynti</h4>
              <p>Lipunmyynti ja asiakaspalvelu.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/asiantuntijapalvelut/kiinteiston-pelastussuunnitelma">Kiinteistön pelastussuunnittelma</Link>
          <Link to="/muutpalvelut/vaatesailytys">Vaatesäilytys</Link>
          <Link to="/muutpalvelut/radiopuhelin">Radiopuhelimet</Link>
          <Link to="/muutpalvelut/defibrillaattori">Defibrillaattorit</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage21WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_1_1;
