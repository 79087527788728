import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQ';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage21WhyChoose from '../../components/ServicePage21WhyChoose';
import ServicePageForm from '../../components/ServicePageForm';
import Testimonial from '../../components/Testimonial';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_1_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
            <h2>Järjestyksenvalvontapalvelut</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50 text-center" id="content">
          <Row>
            <Col sm={{ span: 8, offset: 2}}>
              <h1>TAPAHTUMATURVALLISUUS</h1>
              <h3>TURVALLISUUTTA JA ASIAKASPALVELUA AMMATTITAIDOLLA</h3>
              <p>
                Tuotamme järjestyksenvalvontapalveluita erilaisiin ja kaiken kokoisiin tapahtumiin oli kyseessä sitten yleisö- tai yksityistilaisuus, sisä- tai ulkoilmatapahtuma, yleinen kokous, messut, festivaalit urheilu- tai musiikkitapahtumat. Osallistumme mielellämme myös tapahtuman suunnitteluun sekä avustamme viranomais- ja lupa asioissa. Tapahtumaturvallisuuden ammattilaisillamme on vuosien kokemus tapahtumien turvallisesta läpiviemisestä ja tarjoamme asiakkaillemme tapahtumaturvallisuuden palvelut tarvittaessa avaimet käteen periaatteella.
              </p>

              <h4>Miksi järjestyksenvalvontapalvelut ovat tärkeitä?</h4>
              <p className="p-b-50">
                Järjestyksenvalvonta on usein edellytys tapahtumien järjestämiselle ja se on näkyvin osa tapahtuman turvallisuutta. Osaava, ammattimainen ja asiakaslähtöinen turvallisuushenkilöstö takaa sujuvat järjestelyt tapahtuma alueella. Turvallisuuteen panostaminen lisää myös tapahtuman yleistä viihtyvyyttä, kun asioita tapahtuma alueella seurataan jatkuvasti ja ongelmakohtiin reagoidaan ennaltaehkäisevästi ja estävästi.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray">
          <Row>
            <Col>
              <Container className="bg-white p-b-25 p-t-25 text-center">
                <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                <NewReferences references={['noho', 'finlandia', 'jokerit', 'rajupaja', 'moomin', 'porssiTalo', 'porssiKlubi', 'vanha', 'allas', 'komedia', 'kulttuuri', 'casino']} />
              </Container>
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Yleisvalvonta</h4>
              <p>Järjestyksen ja turvallisuuden valvonta, palo- ja pelastusturvallisuuden valvonta, pelastustoiminnan turvaaminen, järjestyshäiriöihin puuttuminen ja muiden erilaisten tilanteiden ratkaiseminen, asiakaspalvelu.</p>

              <h4>Turvaryhmä</h4>
              <p>Järjestyksen ja turvallisuuden valvonta, palo- ja pelastusturvallisuuden valvonta, pelastustoiminnan turvaaminen, järjestyshäiriöihin ja muihin erilaisiin tilanteisiin reagoiminen tapahtuman painopiste alueilla.</p>

              <h4>Turvatarkastus ja kulunvalvonta</h4>
              <p>Tapahtumapaikan tai -alueen turvatarkastukset sisäänkäynneillä, lipuntarkastus, kulunvalvonta sisäänkäynneillä, henkilöpapereiden tarkastus, asiakaspalvelu.</p>

              <h4>Lavaturvallisuus</h4>
              <p>Esiintyjien, esiintymislavan tai muun näyttämön turvaaminen, yleisön turvallisuuden ylläpitäminen ja erilaisiin tilanteisiin reagoiminen.</p>

              <h4>Anniskelu- ja VIP-alueet</h4>
              <p>Yleisen järjestyksen ja turvallisuuden valvonta, kulunvalvonta, henkilöpapereiden tarkastus, asiakaspalvelu.</p>

              <h4>Raportointi</h4>
              <p>Havainnoista ja toimenpiteistä raportointi.</p>

              <h4>Vaatesäilytys</h4>
              <p>Vaatesäilytys Tapahtumapaikan tai - alueen vaatesäilytys, löytötavaroiden hallinnointi ja asiakaspalvelu.</p>

              <h4>Lipunmyynti</h4>
              <p>Lipunmyynti ja asiakaspalvelu.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/asiantuntijapalvelut/turvallisuuspaallikko">Turvallisuuspäällikkö</Link>
          <Link to="/asiantuntijapalvelut/tapahtuman-turvallisuus-ja-pelastussuunnitelma">Tapahtuman turvallisuus- ja pelastussuunnitelma</Link>
          <Link to="/asiantuntijapalvelut/ensiapusuunnitelma">Tapahtuman ensiapusuunnitelma</Link>
          <Link to="/muutpalvelut/ensiapu">Ensiapu</Link>
          <Link to="/asiantuntijapalvelut/liikenteenohjaussuunnitelma">Tapahtuman liikenteenohjaussuunnitelma</Link>
          <Link to="/muutpalvelut/liikenteenohjaus">Liikenteenohjaus</Link>
          <Link to="/vartiointipalvelut/henkilosuojaus">Henkilösuojaus</Link>
          <Link to="/muutpalvelut/vaatesailytys">Vaatesäilytys</Link>
          <Link to="/muutpalvelut/radiopuhelin">Radiopuhelimet</Link>
          <Link to="/muutpalvelut/defibrillaattori">Defibrillaattorit</Link>
        </ServicePageOtherLinks>

        <ServicePage21WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_1_1;
