import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Banner from '../../components/Banner'
import ContactPerson from '../../components/ContactPerson'
import './style.scss';

import harri from './harri.puustjärvi.jpg';
import jan from './jan.johansson.jpg';
import jesse from './jesse.miettinen.jpg';
import jyrki from './jyrki.varvas.jpg';
import sami from './sami.ylitalo.jpg';
import tero from './tero.toivanen.jpg';
import ossi from './ossi.pietiläinen.jpg';
import mikko from './mikko_seppanen_2_netti.jpg';
import mika from './mika.mielonen.jpg';
import caroline from './caroline.jpg';

class ContactPage extends Component {
  render() {
    return (
      <div className="page contactpage">
        <Banner>
          <h1>Yhteystiedot</h1>
        </Banner>

        <Container className="p-t-100 p-b-100">
            <Row>
              <Col span={12}>
                <h2>Toimipisteet</h2>
                <Table responsive className="text-center">
                  <thead>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <h4>Helsinki</h4>
                        <br/>
                        <p>Ruosilankuja 3 E<br />00390 Helsinki</p>
                      </td>

                      <td>
                        <h4>Tampere</h4>
                        <br/>
                        <p>Puutarhakatu 33<br />33210 Tampere</p>
                      </td>

                      <td>
                        <h4>Turku</h4>
                        <br/>
                        <p>Artturinkatu 2<br />Saippua Center<br />20200 Turku</p>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>

          <Container fluid className="bg-gray">
            <Row> 
              <Col>
                <Container>
                  <Row>
                    <Col>
                      <h2>Yhteydenotto</h2>
                      <Table responsive className="text-center">
                        <thead>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <h4>Myynti</h4>
                              <br/>
                              <a href="mailto:myynti@nordicsec.fi">myynti@nordicsec.fi</a>
                            </td>

                            <td>
                              <h4>Laskutus ja muut tiedustelut</h4>
                              <br/>
                              <a href="mailto:toimisto@nordicsec.fi">toimisto@nordicsec.fi</a>
                            </td>

                            <td>
                              <h4>Palkanmaksu</h4>
                              <br/>
                              <a href="mailto:palkat@nordicsec.fi">palkat@nordicsec.fi</a>
                            </td>
                          </tr>

                          <tr>
                            <td></td>
                            <td>
                              <h4>Vastaava hoitaja</h4>
                              <br/>
                              <a href="mailto:vastaavahoitaja@nordicsec.fi">vastaavahoitaja@nordicsec.fi</a>
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <p className="text-center" style={{color: "#888"}}>Vastaamme yhteydenottoihin 3 arkipäivän kuluessa</p>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>

          <Container className="p-t-50">
            <Row>
              <Col>
                <h3>Toimisto</h3>
                <Table responsive>

                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                      <ContactPerson name="Mika Mielonen" title="Toimitusjohtaja" phone="+358 (0)40 524 5935" phonelink="+358405245935" email="mika.mielonen@nordicsec.fi" image={mika} />
                      </td>
                      <td>
                        <ContactPerson name="Jesse Miettinen" title="Kenttäjohtaja" phone="+358 (0) 45 320 7800" phonelink="+358453207800" email="jesse.miettinen@nordicsec.fi" image={jesse} />
                      </td>
                      <td>
                        <ContactPerson name="Sami Ylitalo" title="Liiketoimintajohtaja" phone="+358 (0) 44 087 3161" phonelink="+358440873161" email="sami.ylitalo@nordicsec.fi" image={sami} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ContactPerson name="Jyrki Varvas" title="Myyntipäällikkö" phone="+358 (0) 41 544 6861" phonelink="+358415446861" email="jyrki.varvas@nordicsec.fi" image={jyrki} />
                      </td>
                      <td>
                        <ContactPerson name="Caroline Törrönen" title="Toimistoassistentti" phone="" phonelink="" email="caroline.torronen@nordicsec.fi" image={caroline} />
                      </td>
                    </tr>
                    <tr>
                    </tr>

                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>

          <Container fluid className="p-t-50 bg-gray">
            <Row>
              <Col>
                <Container>
                  <Row>
                    <Col>
                      <h3>Aluevastaavat</h3>
                      <Table responsive>

                        <thead>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <h4 className="text-center" style={{height: '3em'}}>UUSIMAA</h4>
                              <ContactPerson name="Ossi Pietiläinen" phone="+358 (0)40 5002 593" phonelink="+358405002593" email="ossi.pietilainen@nordicsec.fi" image={ossi} />
                            </td>
                            <td>
                              <h4 className="text-center" style={{height: '3em'}}>UUSIMAA</h4>
                              <ContactPerson name="Mikko Seppänen" phone="+358 (0)44 024 5454" phonelink="+3580440245454" email="mikko.seppanen@nordicsec.fi" image={mikko} />
                            </td>
                            <td>
                              <h4 className="text-center" style={{height: '3em'}}>VARSINAIS-SUOMI, SATAKUNTA, POHJOIS-POHJANMAA</h4>
                              <ContactPerson name="Jyrki Varvas" phone="+358 (0)41 544 6861" phonelink="+358415446861" email="jyrki.varvas@nordicsec.fi" image={jyrki} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4 className="text-center" style={{height: '3em'}}>PIRKANMAA</h4>
                              <ContactPerson name="Jan Johansson" phone="+358 (0)40 088 9222" phonelink="+3580400889222" email="jan.johansson@nordicsec.fi" image={jan} />
                            </td>
                            <td>
                              <h4 className="text-center" style={{height: '3em'}}>PÄIJÄT- JA KANTA-HÄME, PIRKANMAA, KYMEENLAAKSO</h4>
                              <ContactPerson name="Tero Toivanen" phone="+358 (0)44 085 8244" phonelink="+3580440858244" email="tero.toivanen@nordicsec.fi" image={tero} />
                            </td>
                            <td>
                              <h4 className="text-center" style={{height: '3em'}}>KARJALA, SAVO, KAINUU, ETELÄ- JA KESKI- POHJANMAA</h4>
                              <ContactPerson name="Jesse Miettinen" phone="+358 (0)45 320 7800" phonelink="+358453207800" email="jesse.miettinen@nordicsec.fi" image={jesse} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4 className="text-center" style={{height: '3em'}}>KESKI-SUOMI</h4>
                              <ContactPerson name="Sami Ylitalo" phone="+358 (0) 44 087 3161" phonelink="+358440873161" email="sami.ylitalo@nordicsec.fi" image={sami} />
                            </td>
                            <td>
                              <h4 className="text-center" style={{height: '3em'}}>POHJANMAA, LAPPI</h4>
                              <ContactPerson name="Harri Puustjärvi" phone="+358 (0)40 772 7262" phonelink="+358407727262" email="harri.puustjarvi@nordicsec.fi" image={harri} />
                            </td>
                            
                          </tr>

                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>

          </Container>
      </div>
    );
  }
}

export default ContactPage;
