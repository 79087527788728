import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      loading: true,
      error: null
    };
  }

  componentDidMount() {
    this.fetchQuestions();
  }

  componentDidUpdate(prevProps) {
    // Refetch if the URL changes
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.fetchQuestions();
    }
  }

  fetchQuestions() {
    this.setState({ loading: true });
    
    // Get the current URL path
    const url = this.props.location.pathname;
    
    fetch(`https://nsbackend.campaigns.fi/questions/public?url=${encodeURIComponent(url)}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.setState({
          questions: data,
          loading: false,
          error: null
        });
      })
      .catch(error => {
        console.error('Error fetching FAQ questions:', error);
        this.setState({
          questions: [],
          loading: false,
          error: error.message
        });
      });
  }

  render() {
    const { questions, loading, error } = this.state;
    
    if (loading) {
      return (
        <Container fluid className="p-b-50 p-t-50">
          <Row>
            <Col>
              <Container>
                <Row>
                  <Col sm={{ span: 8, offset: 2 }}>
                    <h3 className="p-b-25">Meiltä usein kysyttyä:</h3>
                    <p>Ladataan kysymyksiä...</p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      );
    }

    if (error) {
      console.error('Error displaying FAQ:', error);
      return null; // Don't show anything if there's an error
    }

    if (questions.length === 0) {
      return null; // Don't show the FAQ section if there are no questions
    }

    return (
      <Container fluid className="p-b-50 p-t-50">
        <Row>
          <Col>
            <Container>
              <Row>
                <Col sm={{ span: 8, offset: 2 }}>
                  <h3 className="p-b-25">Meiltä usein kysyttyä:</h3>
                  
                  {questions.map((question, index) => (
                    <div key={index}>
                      <h4>{question.title}</h4>
                      <p dangerouslySetInnerHTML={{ __html: question.body }}></p>
                    </div>
                  ))}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(FAQ);
