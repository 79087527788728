import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQ';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage21WhyChoose from '../../components/ServicePage21WhyChoose';
import ServicePageForm from '../../components/ServicePageForm';
import Testimonial from '../../components/Testimonial';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_1_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
            <h2>Järjestyksenvalvontapalvelut</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50 text-center" id="content">
          <Row>
            <Col sm={{ span: 8, offset: 2}}>
              <h1>LEIRINTÄALUEET</h1>
              <h3>TURVALLISUUTTA JA ASIAKASPALVELUA AMMATTITAIDOLLA</h3>
              <p>
                Leirintäalueen järjestyksenvalvonnalla mahdollistat alueen yleisen järjestyksen ja turvallisuuden valvonnan ja ylläpidon. Järjestyksenvalvojamme huolehtivat alueen palo- ja pelastusturvallisuudesta ja toimivat ensivasteena alueella sattuvissa ensiaputilanteissa. Voimme tuottaa järjestyksenvalvontapalvelun sesonkiluonteisesti tai ympärivuorokautisesti riippuen asiakkaan tarpeesta ja toiminnan laajuudesta.
              </p>

              <h4>Miksi järjestyksenvalvontapalvelut ovat tärkeitä?</h4>
              <p className="p-b-50">
                Järjestyksenvalvontapalvelulla varmistat, että apu on aina lähellä kun sitä tarvitaan, oli kyse sitten päivystyksellisistä asiakaspalvelutilanteista, palo- ja pelastusturvallisuuden varmistamisesta, ensiavun antamisesta, yleisen järjestyksen ja turvallisuuden ylläpidosta tai muista ongelma tai häiriötilanteista. Paikalla on tarvittaessa aina alueen ja sen ominaisuudet tunteva henkilö.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray">
          <Row>
            <Col>
              <Container className="bg-white p-b-25 p-t-25 text-center">
                <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                <NewReferences references={['noho', 'finlandia', 'oldIrish', 'rajupaja', 'moomin', 'porssiTalo', 'vanha', 'allas', 'komedia', 'kulttuuri', 'ilona', 'london', 'escape']} />
              </Container>
            </Col>
          </Row>
        </Container>

        <Container className="p-b-50 p-t-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
            <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Yleisvalvonta</h4>
              <p>Järjestyksen ja turvallisuuden valvonta, järjestyshäiriöihin puuttuminen ja muiden erilaisten tilanteiden ratkaiseminen, alueen henkilökunnan turvaaminen, tarkastuskierrokset, päivystys ja hälytyksiin vastaaminen, ensiapu, asiakaspalvelu.</p>

              <h4>Palo-, pelastus- ja poistumisturvallisuus</h4>
              <p>Palo-, pelastus- ja poistumisturvallisuuden valvonta alueella, pelastustoiminnan turvaaminen, pelastusteiden esteettömyyden varmistaminen.</p>

              <h4>Raportointi</h4>
              <p>Havainnoista ja toimenpiteistä raportointi.</p>

              <h4>Tekninenvalvonta</h4>
              <p>Alueen kamera- ja hälytysvalvonta.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/asiantuntijapalvelut/tapahtuman-turvallisuus-ja-pelastussuunnitelma">Tapahtuman turvallisuus- ja pelastussuunnitelma</Link>
          <Link to="/asiantuntijapalvelut/kiinteiston-pelastussuunnitelma">Kiinteistön pelastussuunnittelma</Link>
          <Link to="/muutpalvelut/radiopuhelin">Radiopuhelimet</Link>
          <Link to="/muutpalvelut/ensiapu">Ensiapu</Link>
          <Link to="/muutpalvelut/defibrillaattori">Defibrillaattorit</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage21WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_1_1;
