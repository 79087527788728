import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQ';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage21WhyChoose from '../../components/ServicePage21WhyChoose';
import ServicePageForm from '../../components/ServicePageForm';
import Testimonial from '../../components/Testimonial';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_1_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
            <h2>Järjestyksenvalvontapalvelut</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50 text-center" id="content">
          <Row>
            <Col sm={{ span: 8, offset: 2}}>
              <h1>JOUKKOLIIKENNE JA LIIKENNEASEMAT</h1>
              <h3>TURVALLISUUTTA JA ASIAKASPALVELUA AMMATTITAIDOLLA</h3>
              <p>
                Joukkoliikenteen ja liikenneasemien järjestyksenvalvonnalla mahdollistetaan matkustajien ja tiloissa asioivien turvallisuus. Lisäksi järjestyksenvalvonnalla ennaltaehkäistään ja estetään häiriöitä, jotka voivat vaikuttaa julkisen liikenteen sujuvuuteen ja liiketoimintaan. Järjestyksenvalvojat työskentelevät yhteistyössä alueensa viranomaisten ja muiden sidosryhmien kanssa. Järjestyksenvalvojat toimivat tehtävissään aina ammattimaisesti ja asiakaslähtöisesti.
              </p>

              <h4>Miksi järjestyksenvalvontapalvelut ovat tärkeitä?</h4>
              <p className="p-b-50">
                Järjestyksenvalvojat estävät ja ennaltaehkäisevät erilaisia rikoksia sekä pyrkivät rauhoittamaan läsnäolollaan asiointiympäristöä. Tavoitteena on estää häiriökäyttäytymistä ja ylläpitää järjestystä ja turvallisuutta toimialueella. Järjestyksenvalvojat toimivat tiiviissä yhteistyössä alueensa viranomaisten kanssa.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray">
          <Row>
            <Col>
              <Container className="bg-white p-b-25 p-t-25 text-center">
                <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                <NewReferences references={['noho', 'finlandia', 'oldIrish', 'rajupaja', 'moomin', 'porssiTalo', 'vanha', 'allas', 'komedia', 'kulttuuri', 'ilona', 'london', 'escape']} />
              </Container>
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Yleisvalvonta</h4>
              <p>Järjestyksen ja turvallisuuden valvonta, järjestyshäiriöihin puuttuminen ja muiden erilaisten tilanteiden ratkaiseminen, alueella asioivien turvaaminen, tarkastuskierrokset, hälytyksiin vastaaminen, päivystäminen, ensiapu, asiakkaiden opastaminen ja neuvonta.</p>

              <h4>Palo- ja pelastusturvallisuus</h4>
              <p>Palo-, pelastus- ja poistumisturvallisuuden valvonta joukkoliikenteessä ja liikenneasemilla, pelastustoiminnan turvaaminen, pelastusteiden esteettömyyden varmistaminen.</p>

              <h4>Raportointi</h4>
              <p>Havainnoista ja toimenpiteistä raportointi.</p>

              <h4>Yhteistyö</h4>
              <p>Viranomaisyhteistyö alueellisten viranomaisten kanssa sekä yhteistyö muiden sidosryhmien kanssa.</p>

              <h4>Tekninenvalvonta</h4>
              <p>Joukkoliikenteen ja liikenneasemien kamera- ja hälytysvalvonta.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/asiantuntijapalvelut/kiinteiston-pelastussuunnitelma">Kiinteistön pelastussuunnittelma</Link>
          <Link to="/muutpalvelut/vaatesailytys">Vaatesäilytys</Link>
          <Link to="/muutpalvelut/radiopuhelin">Radiopuhelimet</Link>
          <Link to="/muutpalvelut/defibrillaattori">Defibrillaattorit</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage21WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_1_1;
